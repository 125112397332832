
.detailsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .col {
    max-width: 400px;
    margin: 15px;
    height: auto;
    width: 80%;
  }
  
  .movieImage {
    border-radius: 15px;
  }
  
  .movieDetails {
    font-size: 1.5rem;
  }
  
  .firstItem {
    margin-top: 0;
  }