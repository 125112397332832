.movieCard {
    list-style: none;
    font-size: 1.5rem;
    text-align: center;
  }

  .movieCard:hover{
    opacity: 0.8;
  }
  
  .movieImage {
    border-radius: 10px;
    background-color: azure;
    width:230px;
    height: 345px;
    
  }
  
  @media (max-width: 560px) {
    .movieImage {
      width: 90%;
      height: auto;
    }
  }